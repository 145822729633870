// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn-primary {
  background-color: $orange;
  border-color: $orange;
  &:hover, &:focus {
    background-color: darken($color: $orange, $amount: 10%);
    border-color: darken($color: $orange, $amount: 10%);
  }
}
.btn-link {
  border-bottom: 3px solid $orange;
  color: black;
  &:hover, &:focus {
    border-bottom: 3px solid $orange;
  }
}