.pagination {
	border: none;
	text-transform: lowercase;
	.page-item {
		& + .page-item {
			border-left: 1px solid $lightgray;
		}
		.page-link {
			background: none;
			border: none;
			padding: .5rem 1.25rem;
			color: $orange;
			i {
				color: $orange;
			}
		}
		&.active {
			.page-link,
			.page-link i {
				color: $darkgray;
			}
		}
		&.disabled {
			.page-link,
			.page-link i {
				color: $lightgray;
			}
		}
	}
}
