.about-quote {
  background: linear-gradient(rgba(black, 0.3), rgba(black, 0.3)), url('/assets/images/handshake.jpg') no-repeat center/cover;
  padding: 9em 1em;
  @include media-breakpoint-up(md) {
    padding: 5em 2em;
    margin: 2em 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 9em;
  }
  .blockquote{ 
    color: white; 
    padding: 1em 2em;
    font-weight: 300;
    font-size: 1.5rem;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
}

.page-content {
  margin: 0 0!important;
}