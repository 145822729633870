// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include media-breakpoint-up(md) {
    height: 25em;
    margin-bottom: -5em;
  }
}