// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    flex: 1 0 auto;
    .page-content {
      margin: 3em 0 0;
      .container {
        img {
          width: 100%;
          height: auto;
        }
      }
      .homepage & {
        margin-bottom: 0;
      }
    }
  }
}

.container {
  .entry-body {
    margin: 5em 0;
    h2, h3 {
      padding: 0 0 2em;
      text-align: center;
    }
  }
  .space-right {
    padding-right: 5rem;
  }
  .company-info {
    padding-top: 80px;
  }
}

@include media-breakpoint-down(sm) {
  .container {
    .company-info {
      padding-top: 0;
    }
  }
}

.border-left {
  padding-left: 4em;
}

li {
  list-style: none;
}
ul {
  padding-left: 0;
}