.contact {
  main {
    margin: 5em 1em;
    .contact-information {
      margin-bottom: 3em;
      .location {
        margin-top: 2em;
      }
      .fa-phone-iphone {
        margin-left: -11px;
      }
    }
    
  }
}
