input[placeholder="Search"] {
  border-bottom: 3px solid $orange;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  &:focus {
    box-shadow: none;
    border-bottom: 3px solid $orange;
  }
}

.input-group-append button {
  border-radius: 0;
  &:hover, &:focus {
    color: $orange;
  }
}