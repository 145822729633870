.attorney-header {
  padding: 1em 0 0;
  background: linear-gradient(rgba(black, 0.5), rgba(black, 0.5)), url('../assets/images/header-photos/attorney-bg.jpg') no-repeat center/cover;
  h3 { color: $orange; font-weight: 200; }
  > .container > .row {
   position: relative;
   .col-md-6:first-child {
    position: absolute;
    bottom: 0;
    z-index: 2;
    background: rgba(black, 0.8);
    width: 100%;
    padding: 2em 1em;
    @include media-breakpoint-down(sm) {
      background: rgba( black, 1);
    }
    @include media-breakpoint-up(md) {
     position: initial;
     z-index: initial;
     background: none;
     display: flex;
     flex-direction: column;
     justify-content: center;
    }
    a { max-width: 20%; }
   }
   .col-md-6:nth-child(2) {
     padding-top: 2em;
    .attorney-detail-image {
     background-size: 500px;
     background-repeat: no-repeat;
     background-position: center 35%;
     height: 25em;
    }
    @media only screen and (max-width: 576px) {
      margin-right: initial;
      margin-left: initial;
    }
    @media only screen and (max-width: 768px) {
      padding-top: 0;
      padding-bottom: 11em;
      .featured-attorney-content {
        padding-bottom: 18em;
      }
    }
   }
   @media only screen and (max-width: 350px) {
    .featured-attorney-content {
      padding-bottom: 18em!important;
    }
  }
  }
  p.lead {
    display: none;
    @include media-breakpoint-up(md){
      display: initial;
      font-size: 1rem;
    }
    @include media-breakpoint-up(lg){
      font-size: 1.25rem;
    }
  }
 }
.attorneys {
 #areaTabs {
  // flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 1em;
  li {
   flex: 1 1 auto;
   white-space: nowrap;
  }
  a {
   border: none;
   &.active {
    border-bottom: 5px solid $orange;
   }
  }
 }
 .attorney-card {
  color: #000;
  margin-bottom: 2em;
  .card-img-top {
   height: 20em;
   background-color: $lightgray;
   background-position: center 30%;
   background-size: 100%;
   background-repeat: no-repeat;
  }
  h5 {
   color: $orange;
  }
  .card-body {
    height: 15em;
  }
 }
}

.attorney-background {
  color: white;
  padding: 15em 0;
  .experience {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1em;
    @include media-breakpoint-up(md) {
      border-right: 5px solid $orange;
    }
    span {
     color: $orange;
     font-size: 4rem;
    }
   }
   .col-md-9 {
     @include media-breakpoint-up(md){
       padding-top: 1em;
     }
   }
   .blockquote{ 
    color: white; 
    padding: 1em 2em;
    font-size: 1rem;
    margin: 0;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  .accolade-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .feature-number {
    h1 {
      color: $orange;
    }
  }
}

.attorney-practice-areas {
 padding: 5em 1em;
}

.attorney-entry {
  padding: 5em 1em;
    @include media-breakpoint-down(sm) {
      .col-md-4 {
        padding-bottom: 1em;
      }
      .col-md-8 {
        padding-left: 15px;
      }
      .border-left {
        border-left: none!important;
        border-top: 1px solid #dee2e6 !important;
        padding-top: 2em;
      }
    }
  p {
    strong {
      text-transform: uppercase;
    }
  }
}

