// -----------------------------------------------------------------------------
// Basic typography style for copy text
// -----------------------------------------------------------------------------

html {
  font-size: 1rem;
}

body{
  font-family: 'Hind', sans-serif;
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.1rem;
  }
}


a {
  color: $orange;
  border-bottom: 2px solid $orange;
  &:hover, &:focus {
    color: black;
    text-decoration: none;
  }
}

.blockquote {
  border-left: 5px solid $orange;
  padding-left: 1em;
  margin-bottom: 0;
  margin: 1em;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
}

i { color: $orange }