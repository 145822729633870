// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.navbar {
  border-bottom: .5px solid white;
  a {
    border-bottom: none;
  }
  .navbar-brand {
    width: 3em;
    height: auto;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    .about-us & { opacity: 1; }
  }
  &.bg-dark {
    background-color: $darkgray !important;
    border-bottom: none!important;
    .navbar-brand {
      opacity: 1;
    }
  }
  .nav-link { color: white !important; }
  .contact & , .practice-areas & , .login & {
    @extend .bg-dark;
  }
}
.jumbotron {
  padding: 8em 0 8em 0;
  color: white;
  background: #333;
  border-radius: 0;
  text-align: center;
  &.home {
    padding: 8rem 2rem 8rem 2rem;
    height: 100vh;
    position: relative;
    overflow: hidden;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .message {
      @include media-breakpoint-up(md){
        width: 40%;
      }
    }
    .teaser {
      display: none;
      @include media-breakpoint-up(md) {
        display: initial;
      }
    }
    .scroll-downs {
      position: absolute;
      right: 0;
      bottom: 2em;
      left: 0;
      margin: auto;
      width :34px;
      height: 55px;
    }
    .mousey {
      width: 3px;
      padding: 10px 15px;
      height: 35px;
      border: 2px solid #fff;
      border-radius: 25px;
      opacity: 0.75;
      box-sizing: content-box;
    }
    .scroller {
      width: 3px;
      height: 10px;
      border-radius: 25%;
      background-color: #fff;
      animation-name: scroll;
      animation-duration: 2.2s;
      animation-timing-function: cubic-bezier(.15,.41,.69,.94);
      animation-iteration-count: infinite;
    }
    @keyframes scroll {
      0% { opacity: 0; }
      10% { transform: translateY(0); opacity: 1; }
      100% { transform: translateY(15px); opacity: 0;}
    }
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -1;
      transform: translateX(-50%) translateY(-50%);
      background-size: cover;
    }
  }

  a { margin-bottom: 1em; }
  img {
    max-width: 6em;
  }
  @include media-breakpoint-up(md) {
    text-align: left;
  }
}
