// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
  color: white;
  background: url('/assets/branding/logo-gray.svg') no-repeat center;
  background-color: $darkgray;
  background-size: 300px;
  @include media-breakpoint-up(lg) {
    background-size: 500px;
  }
  background-color: $darkgray;
  padding: 2em 0;
  text-align: center;
  a {
    color: white; 
    &:hover, &:focus {
      color: $orange;
    }
  }
  @include media-breakpoint-up(md) {
    text-align: left;
  }
  hr {
    border-color: orange;
  }
  .quick-links {
    a {
      border-bottom: none;
    }
    ul {
      list-style: none;
      padding-left: 0;
      li { padding: 0.5em 0; }
    }
  }
}
.footer-bottom {
  background: $orange;
  padding: 1em 0;
  color: white;
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: left;
  }
  .badges {
    img {
      width: 60px;
      height: auto;
    }
  }
  .copyright {
    text-align: center;
    p { margin-top: 1em; }
    @include media-breakpoint-up(md) {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p { margin-bottom: 0; }
    }
  }
}
