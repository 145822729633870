.entry-type-news,
.entry-type-newsArticle {
	.page-content {
		.article-link {
			font-family: 'Oxygen', sans-serif;
			font-weight: bold;
			text-transform: uppercase;
			border-bottom: none;
		}
		.post-date {
			font-family: 'Oxygen', sans-serif;
			font-weight: bold;
			text-transform: uppercase;
			color: rgb(100, 100, 100);
			margin: 1rem 0 2rem 0;
		}
		.article-card {
			margin: 15px 0;
			.post-date {
				margin: 0 0 1rem 0;
			}
			.card-text {
				margin-bottom: 3rem;
			}
			.article-link {
				position: absolute;
				bottom: 1.5rem;
				left: 1.25rem;
			}
			.stretched-link {
				width: 0;
				height: 0;
				padding: 0;
				margin: 0;
			}
		}
		.article-footer {
			hr {
				border-color: $orange;
				margin-bottom: 0.5rem;
			}
			font-family: 'Oxygen', sans-serif;
			text-transform: uppercase;
			font-weight: 900;
			.share-label {
				position: relative;
				vertical-align: middle;
				margin-right: 1.5rem;
			}
			.print-label {
				position: relative;
				vertical-align: middle;
				margin-right: 1.25rem;
			}
			.print-link {
				text-decoration: none;
				position: relative;
				vertical-align: middle;
				cursor: pointer;
			}
			.addthis_inline_share_toolbox {
				position: relative;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}
