.login {
	main {
		margin: 5em 1em;
		.login-form {
			border: 1px solid $lightgray;
			border-radius: 1em;
			padding: 2em 1em;
			max-width: 20em;
			h1 { font-size: 2em; }
			form {
				text-align: left;
				input[type="submit"] {
					margin-bottom: 1em;
				}
			}
		}
	}
}

.entry-type-resourceList, .entry-type-resource {
	main {
		margin: 5em 1em;
		.resource-entries {
			ul li:not(:last-child) {
				margin: 1.25rem 0;
				padding: 0 0 1.25rem 0;
				border-bottom: 1px solid $hr-border-color;
			}
			.resource-group-title {
				margin: 5.0rem 0 2.5rem 0 !important;
				padding: 0 0 0.625rem 0 !important;
				border-bottom: 1px solid $orange;
			}
			.resource-item-title {
				margin: 0 0 2.5rem 0 !important;
			}
			.resource-item-teaser {
				margin: 0.625rem 0 0.3125rem 0;
			}
		}
	}
}

.entry-type-resource {
	.entry-body {
		.entry-title {
			margin: 0 0 2.5rem 0;
			padding: 0 0 1.25rem 0;
			border-bottom: 1px solid $hr-border-color;
		}
	}
}