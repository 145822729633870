.area-row {
	position: relative;
	overflow: hidden;
	.col-lg-4 {
		order: 2;
		color: white;
		padding: 1em 2em;
		text-align: center;
		position: relative;
		z-index:100;
		@include media-breakpoint-down(md) {
				padding: 5em 2em;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				flex-direction: column;
		}
		@include media-breakpoint-up(md) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: left;
			a {
				width: 6em; 
				text-align: center;
			}
			.triangle {
				background-size: cover;
				position: absolute;
				height: 100%;
				width: 15em;
				left: 100%;
				top:0;
				background-repeat: no-repeat;
				background-position: top;
			}
		}
		@include media-breakpoint-down(md) {
			text-align: center;
		}
	}
	.col-lg-8 {
		order: 1;
		height: 20em;
		position: relative;
		@include media-breakpoint-up(lg) {
			height: 30em;
		}
		@include media-breakpoint-down(md) {
			display:none;
		}
		.attorney-image {
			background-size: 500px;
			background-repeat: no-repeat;
			height: 100%;
			background-position: center 25%; 
		}
	}
	.area-background {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: absolute;
		height: 110%;
		width: 110%;
		z-index: -1;
		top: 0;
		left: 0;
		filter: blur(5px);
		overflow: hidden;
		margin: -5px -10px -10px -5px;
	}
	// Attorney Right First Row
	&:first-child {
		.col-lg-4 {
			background: $orange;
			position: relative;
			a {
				color: white;
				border-color: white;
				&:hover, &:focus {
					color: black;
				}
			}
			@include media-breakpoint-up(md) {
				order: 1;
				.triangle {
					background-image: url('/assets/background/triangle-orange.svg');
				}
			}
		}
		.col-lg-8 {
			@include media-breakpoint-up(md) {
				order: 2;
				.attorney-image{
					background-position: top 25% right 3em;
				}
			}
			.img-fuild, .featured-attorney-image {
				position: absolute;
				right: -100px;
			}
			.featured-attorney-image:nth-child(1) {
				margin-right: 525px;
				z-index: 6;
			}
			.featured-attorney-image:nth-child(2) {
				margin-right: 425px;
				z-index: 5;
			}
			.featured-attorney-image:nth-child(3) {
				margin-right: 325px;
				z-index: 4;
			}
			.featured-attorney-image:nth-child(4) {
				margin-right: 225px;
				z-index: 3;
			}
			.featured-attorney-image:nth-child(5) {
				margin-right: 100px;
				z-index: 2;
			}
			.featured-attorney-image:nth-child(6) {
				margin-right: 0;
				z-index: 1;
			}
			@media only screen and (max-width: 1230px) {
				.img-fluid, .featured-attorney-image {
					right: -170px;
				}
			}
			@media only screen and (max-width: 768px) {
				.img-fluid, .featured-attorney-image {
					right: 0;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					right: -115px;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					right: -75px;
				}
				.featured-attorney-image:nth-child(1) {
					margin-right: 140px;
					z-index: 3;
				}
				.featured-attorney-image:nth-child(2) {
					margin-right: 65px;
					z-index: 2;
				}
			}
		}
	}
	// Attorney Left Second Row
	&:nth-child(2) {
		.col-lg-4 {
			background: $darkgray;
			position: relative;
			a {
				margin-left: auto;
				@include media-breakpoint-down(md) {
					margin: 0 auto;
				}
			}
			@include media-breakpoint-up(md) {
				text-align: right;
				//align-items: flex-end;
				.triangle {
					background-image: url('/assets/background/triangle-darkgray.svg');
					right: 100%;
					left: -264px;
				}
			}
			@include media-breakpoint-down(md) {
				text-align: center;
				align-items: center;
			}
		}
		.col-lg-8 {
			@include media-breakpoint-up(md) {
				.attorney-image {
					background-position: top 25% left 3em;
				}
			}
			.img-fuild, .featured-attorney-image {
				position: absolute;
				left: -300px; // Change this if adding more attorneys
			}
			.featured-attorney-image:nth-child(1) {
				margin-left: 525px;
				z-index: 6;
			}
			.featured-attorney-image:nth-child(2) {
				margin-left: 425px;
				z-index: 5;
			}
			.featured-attorney-image:nth-child(3) {
				margin-left: 325px;
				z-index: 4;
			}
			.featured-attorney-image:nth-child(4) {
				margin-left: 225px;
				z-index: 3;
			}
			.featured-attorney-image:nth-child(5) {
				margin-left: 100px;
				z-index: 2;
			}
			.featured-attorney-image:nth-child(6) {
				margin-left: 0;
				z-index: 1;
			}
			@media only screen and (max-width: 1230px) {
				.img-fluid, .featured-attorney-image {
					left: -300px;
				}
			}
			@media only screen and (max-width: 768px) {
				.img-fluid, .featured-attorney-image {
					left: 0;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					left: -115px;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					left: -75px;
				}
				.featured-attorney-image:nth-child(1) {
					margin-left: 140px;
					z-index: 3;
				}
				.featured-attorney-image:nth-child(2) {
					margin-left: 65px;
					z-index: 2;
				}
			}
		}
	}
	// Attorney Right - Wills, Trusts, and Estate Planning
	&:nth-child(3) {
		.col-lg-4 {
			background: white;
			color: black;
			position: relative;
			@include media-breakpoint-up(md) {
				order: 1;
				.triangle {
					background-image: url('/assets/background/triangle-white.svg');
				}
			}
		}
		.col-lg-8 {
			@include media-breakpoint-up(md) {
				order: 2;
				.attorney-image{
					background-position: top 25% right 3em;
				}
			}
			// Start excessive styling for offset attorneys
			.img-fuild, .featured-attorney-image {
				position: absolute;
				right: -230px;
			}
			.featured-attorney-image:nth-child(1) {
				margin-right: 525px;
				z-index: 6;
			}
			.featured-attorney-image:nth-child(2) {
				margin-right: 425px;
				z-index: 5;
			}
			.featured-attorney-image:nth-child(3) {
				margin-right: 325px;
				z-index: 4;
			}
			.featured-attorney-image:nth-child(4) {
				margin-right: 225px;
				z-index: 3;
			}
			.featured-attorney-image:nth-child(5) {
				margin-right: 100px;
				z-index: 2;
			}
			.featured-attorney-image:nth-child(6) {
				margin-right: 0;
				z-index: 1;
			}
			@media only screen and (max-width: 1230px) {
				.img-fluid, .featured-attorney-image {
					right: -260px;
				}
			}
			@media only screen and (max-width: 768px) {
				.img-fluid, .featured-attorney-image {
					right: 0;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					right: -115px;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					right: -75px;
				}
				.featured-attorney-image:nth-child(1) {
					margin-right: 140px;
					z-index: 3;
				}
				.featured-attorney-image:nth-child(2) {
					margin-right: 65px;
					z-index: 2;
				}
			}
		}
	}
	// Attorney Left - Family Law
	&:nth-child(4){
		.col-lg-4 {
			background: $orange;
			position: relative;
			//align-items: flex-end;
			a {
				color: white;
				border-color: white;
				margin-left: auto;
				@include media-breakpoint-down(md) {
					margin: 0 auto;
				}
				&:hover, &:focus {
					color: black;
				}
			}
			@include media-breakpoint-up(md) {
				text-align: right;
				.triangle {
					background-image: url('/assets/background/triangle-orange-reverse.svg');
					right: 100%;
					left: -264px;
				}
			}
			@include media-breakpoint-down(md) {
				text-align: center;
				align-items: center;
			}
		}
		.col-lg-8 {
			@include media-breakpoint-up(md) {
				.attorney-image{
					background-position: top 25% left 3em;
				}
			}
			// Start Excessive Styling
			.img-fuild, .featured-attorney-image {
				position: absolute;
				left: -300px;
			}
			.featured-attorney-image:nth-child(1) {
				margin-left: 525px;
				z-index: 6;
			}
			.featured-attorney-image:nth-child(2) {
				margin-left: 425px;
				z-index: 5;
			}
			.featured-attorney-image:nth-child(3) {
				margin-left: 325px;
				z-index: 4;
			}
			.featured-attorney-image:nth-child(4) {
				margin-left: 225px;
				z-index: 3;
			}
			.featured-attorney-image:nth-child(5) {
				margin-left: 100px;
				z-index: 2;
			}
			.featured-attorney-image:nth-child(6) {
				margin-left: 0;
				z-index: 1;
			}
			@media only screen and (max-width: 1230px) {
				.img-fluid, .featured-attorney-image {
					left: -320px;
				}
			}
			@media only screen and (max-width: 768px) {
				.img-fluid, .featured-attorney-image {
					left: 0;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					left: -115px;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					left: -75px;
				}
				.featured-attorney-image:nth-child(1) {
					margin-left: 140px;
					z-index: 3;
				}
				.featured-attorney-image:nth-child(2) {
					margin-left: 65px;
					z-index: 2;
				}
			}
		}
	}
	// Attorney Right - Business Corporate Law
	&:nth-child(5) {
		.col-lg-4 {
			background: $darkgray;
			position: relative;
			@include media-breakpoint-up(md) {
				order: 1;
				.triangle {
					background-image: url('/assets/background/triangle-darkgray-reverse.svg');
				}
			}
			@include media-breakpoint-down(md) {
				text-align: center;
				align-items: center;
			}
		}
		.col-lg-8 {
			@include media-breakpoint-up(md) {
				.attorney-image {
					order: 2;
					background-position: top 25% right 3em;
				}
			}
			.img-fuild, .featured-attorney-image {
				position: absolute;
				right: -300px;
			}
			.featured-attorney-image:nth-child(1) {
				margin-right: 525px;
				z-index: 6;
			}
			.featured-attorney-image:nth-child(2) {
				margin-right: 425px;
				z-index: 5;
			}
			.featured-attorney-image:nth-child(3) {
				margin-right: 325px;
				z-index: 4;
			}
			.featured-attorney-image:nth-child(4) {
				margin-right: 225px;
				z-index: 3;
			}
			.featured-attorney-image:nth-child(5) {
				margin-right: 100px;
				z-index: 2;
			}
			.featured-attorney-image:nth-child(6) {
				margin-right: 0;
				z-index: 1;
			}
			@media only screen and (max-width: 1230px) {
				.img-fluid, .featured-attorney-image {
					right: -350px;
				}
			}
			@media only screen and (max-width: 768px) {
				.img-fluid, .featured-attorney-image {
					right: 0;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					right: -115px;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					right: -75px;
				}
				.featured-attorney-image:nth-child(1) {
					margin-right: 140px;
					z-index: 3;
				}
				.featured-attorney-image:nth-child(2) {
					margin-right: 65px;
					z-index: 2;
				}
			}
		}
	}
	// Attorney Left - Real Estate
	&:nth-child(6){
		.col-lg-4 {
			background: $orange;
			position: relative;
			//align-items: flex-end;
			a {
				color: white;
				border-color: white;
				margin-left: auto;
				@include media-breakpoint-down(md) {
					margin: 0 auto;
				}
				&:hover, &:focus {
					color: black;
				}
			}
			@include media-breakpoint-up(md) {
				text-align: right;
				.triangle {
					background-image: url('/assets/background/triangle-orange-reverse.svg');
					right: 100%;
					left: -264px;
				}
			}
			@include media-breakpoint-down(md) {
				text-align: center;
				align-items: center;
			}
		}
		.col-lg-8 {
			@include media-breakpoint-up(md) {
				.attorney-image{
					background-position: top 25% left 3em;
				}
			}
			// Start Excessive Styling
			.img-fuild, .featured-attorney-image {
				position: absolute;
				left: -300px;
			}
			.featured-attorney-image:nth-child(1) {
				margin-left: 525px;
				z-index: 6;
			}
			.featured-attorney-image:nth-child(2) {
				margin-left: 425px;
				z-index: 5;
			}
			.featured-attorney-image:nth-child(3) {
				margin-left: 325px;
				z-index: 4;
			}
			.featured-attorney-image:nth-child(4) {
				margin-left: 225px;
				z-index: 3;
			}
			.featured-attorney-image:nth-child(5) {
				margin-left: 100px;
				z-index: 2;
			}
			.featured-attorney-image:nth-child(6) {
				margin-left: 0;
				z-index: 1;
			}
			@media only screen and (max-width: 1230px) {
				.img-fluid, .featured-attorney-image {
					left: -320px;
				}
			}
			@media only screen and (max-width: 768px) {
				.img-fluid, .featured-attorney-image {
					left: 0;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					left: -115px;
				}
			}
			@media only screen and (max-width: 608px) {
				.img-fluid, .featured-attorney-image {
					left: -75px;
				}
				.featured-attorney-image:nth-child(1) {
					margin-left: 140px;
					z-index: 3;
				}
				.featured-attorney-image:nth-child(2) {
					margin-left: 65px;
					z-index: 2;
				}
			}
		}
	}
}

.sub-areas {
	border: 1px solid $lightgray;
	#subAreas {
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
		li {
			white-space: nowrap;
			a {
				border-bottom: none;
				background: $darkgray;
				color: white;
				border-radius: 0;
				border: 1px solid $lightgray;
				&.active {
					background: $orange;
				}
			}
		}
	}
	.tab-content {
		min-height: 20em;
		padding: 1em 3em;
	}
}
